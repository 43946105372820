import {get, post, put} from './request'
import {CurrentUser, UserPracticeInfo} from "@/model/User";

const proxyUrl = process.env.VUE_APP_BASE_URL + '/users'


export default {

    /**
     * 获取当前用户信息
     */
    current: (): Promise<CurrentUser> => {
        return  get(`${proxyUrl}/current`)
    },

    update: (data: {
        name?: string | null,
        avatar?: string | null
    }) => {
        return put(`${proxyUrl}/current`, data)
    },

    // 登录
    loginByTelAndPassword: (data: {tel: string, password: string}): Promise<{accessToken: string, id: string}> => post(`${proxyUrl}/login/tel_password`, data),

    // 电话注册
    registerByTel: (data: {tel: string, password: string, code: string}): Promise<{accessToken: string, id: string}> => post(`${proxyUrl}/register/tel`, data),

    // 找回密码
    getBackPassword: (data: {tel: string, newPassword: string, code: string}): Promise<void> => post(`${proxyUrl}/password/getback`, data),

    practiceInfo: (): Promise<UserPracticeInfo> => get(`${proxyUrl}/current/practice_info`),
}
