import {Ref, ref, watch} from "vue";
import {CurrentUser} from "@/model/User";
import UserAPI from "@/api/UserAPI";
import {UIHandleGet} from "@/views/utils";
import {Tenant} from "@/model/Tenant";
import TenantAPI from "@/api/TenantAPI";

const accessTokenKey = "J-TOKEN-FRO"
const currentUserIdKey = "CURRENT_USER_ID"

export const store = {
    set(key: string, value: any) {
        if (value == null) {
            window.localStorage.removeItem(key)
        } else {
            window.localStorage.setItem(key, value)
        }

    },

    get(key: string) {
        return window.localStorage.getItem(key)
    }
}

export const accessTokenRef = ref<string|null>(null)
export const currentUserId = ref<string|null>(null)
export const getAccessToken = () => {
    if (accessTokenRef.value == null || accessTokenRef.value === "") {
        accessTokenRef.value = store.get(accessTokenKey)
    }
    return accessTokenRef.value
}

// 设置token函数
export const setAccessToken = (accessToken:string|null, userId:string|null) => {
    store.set(accessTokenKey, accessToken)
    accessTokenRef.value = accessToken
    store.set(currentUserIdKey, userId)
    currentUserId.value = userId
}
// 清理token函数
export const clearAccessToken = () => {
    setAccessToken(null, null)
}

// 用户id
export const getCurrentUserId = () => {
    if (currentUserId.value == null || currentUserId.value === "") {
        currentUserId.value = store.get(currentUserIdKey)
    }
    return currentUserId.value
}

// 请求当前用户
export const currentUser = ref<CurrentUser|null>(null)
watch(accessTokenRef, async (value, old) => {

    if (value) {
        await refreshUserFromRemote()
    } else {
        currentUser.value = null
    }
})

export const refreshUserFromRemote = async () => {
    currentUser.value = await UIHandleGet(UserAPI.current())
}


// 登录控制字段
export const loginViewShow = ref<boolean>(false)
export const tenant = ref<Tenant | null>(null)
const requestTenant = async () => {
    try {
        tenant.value = await UIHandleGet(TenantAPI.current())
    } finally {

    }
}


// 搜索文本
export const searchText = ref<string>("")

export function storeInit() {
    getAccessToken()
    getCurrentUserId()
    requestTenant().then()
}
