import {ElMessage} from "element-plus";


export async function  UIHandleGet<T>(promise: Promise<T>, handleFailureMessage = true): Promise<T> {

    try {
        return await promise
    } catch (e: any) {
        console.error(e)
        if (handleFailureMessage) {
            ElMessage(
                {
                    type: "error",
                    message: "请求失败: " + e.message,
                    duration: 1500,
                }
            )
        }
        throw e
    }
}

export function UIHandleUpdate<T>(promise: Promise<T>,
                           successMessage: string = "",
                           handleFailureMessage = true): Promise<T> {

    return new Promise(function (resolve, reject) {
        promise.then((r) => {
            if (successMessage) {
                ElMessage(
                    {
                        type: "success",
                        message: successMessage,
                        center: true
                    }
                )
            }
            resolve(r)
        }).catch((e) => {
            console.error(e)
            if (handleFailureMessage) {
                ElMessage(
                    {
                        type: "error",
                        message: "请求失败: " + e.message,
                        center: true,
                        duration: 1500
                    }
                )
            }
            reject(e)
        })
    })

}

export function distinctMergeArray(id: (e: any) => any, ...arrs: Array<any>[]): any[] {

    let existed = new Set<string>()
    let ret = []
    for (let arr of arrs) {
        for (let arrElement of arr) {
            let key = id(arrElement)
            if (existed.has(key)) {
                continue
            }
            existed.add(key)
            ret.push(arrElement)
        }
    }

    return ret
}

export function parseUrl(url: string): string {
    let prefix = location.protocol + "//" + location.host
    url = url.replace("{{host}}", prefix)
    return url
}
