import axios, {AxiosPromise, AxiosRequestHeaders} from 'axios'
import qs from 'qs'
import {accessTokenRef, clearAccessToken, loginViewShow} from "@/store"
import {encryptByAESPKCS7} from "@/utils/sign";
import {uuid} from "@/utils/uuid";

let k = process.env.VUE_APP_API_SIGN_KEY
let iv =  process.env.VUE_APP_API_SIGN_IV
let rk = reverseString(k)
let riv = reverseString(iv)

export type RequestOptions = {}
type Options = { url: string, method: string, data?: Record<string, any>, headers?: any}

/**
 * 创建请求实例
 */
const service = axios.create({
    // withCredentials: false, // send cookies when cross-domain requests
    timeout: 30000,
    // paramsSerializer: {
    //     serialize: function (params: any) {
    //
    //         console.log(qs.stringify(params, {indices: false}))
    //         debugger
    //         return qs.stringify(params, {indices: false})
    //     }
    // }
})

/**
 * 请求发送前处理
 * @param {*} config
 */
const appHost = process.env.VUE_APP_HOST
function configRequest(config: any) {
    const {signature, rParams} = makeSign(config)


    config.headers = {...config.headers,
        "J-TOKEN-FRO": accessTokenRef.value,
        "J-PREFER-HOST": appHost || location.host,
        "J-SIGN": signature,
        "J-PARAMS": rParams,
        "J-PLATFORM": "PC",
    }
    if (config.method === 'get') {
        // 后台列表分页需要countTotal字段
        config.params = {...config.params, countTotal: 1}

        // 查询条件空字符串置为undefined
        Object.entries(config.params).forEach(([key, value]) => {
            if (value == null || value === '') {
                config.params[key] = undefined
            }
        })
    }

    return config
}

function makeSign(config: any) {
    let url = new URL(config.url)
    let path = url.pathname
    let t = new Date().getTime()
    let n = uuid().toString()
    let data: Record<string, any> = {}

    data["timestamp"] = t
    data["nonce"] = n
    if (config.method === 'post' ||
        config.method === 'delete' ||
        config.method === 'put' &&
        typeof config.data == 'string' &&
        config.data) {
        let dataString = config.data
        if (config.data.length > 200) {
            dataString = dataString.substring(0, 50) + dataString.substring(Math.floor(dataString.length / 2) - 25, Math.floor(dataString.length / 2) + 25) + dataString.substring(dataString.length - 50, dataString.length)
        }

        data['body'] = dataString
    }
    let paramString = Object.keys(data).sort().map((e: string) => {
        return e + "=" + data[e]
    }).join("&");
    paramString = path + "_" + paramString


    let signature = encryptByAESPKCS7(paramString, k, iv)
    let rParams = encryptByAESPKCS7(t + "," + n, rk, riv)
    return {
        signature,
        rParams
    }
}

function reverseString(str: string) {
    // 将字符串转换为数组
    var arr = str.split("");
    // 反转数组
    arr.reverse();
    // 将数组转换回字符串
    var newStr = arr.join("");
    // 返回反转后的字符串
    return newStr;
}

// 请求拦截
service.interceptors.request.use(configRequest, (error) => {

    // do something with request error
    console.error(error) // for debug
    return Promise.reject(error)
})



// 响应拦截
service.interceptors.response.use(
    (response) => {
        const res = response.data
        if (response.status === 200) {
            if (!res.success) {
                const err = Error(res.message) as any
                err.code = res.code;
                // 登陆失效处理
                if (res.code === 403 && location.pathname !== '/login') {
                    clearAccessToken()
                    loginViewShow.value = true
                    throw err
                    // window.location.href = `/login?redirect=` + encodeURIComponent(`${window.location.pathname}${window.location.search}`)
                } else {
                    throw err
                }
            }

            return res.data
        }

        return res
    },
    (error) => {
        throw error
    },
)

const request = (options: Options): Promise<any> => {
    const {url, method, data, headers = {}} = options
    let bodyData = ""
    let h = headers
    if (method == 'post' || method == 'put' || method == 'delete') {
        bodyData = JSON.stringify(data)
    }

    h = Object.assign({'Content-Type': 'application/json'}, headers)

    const query: any = {
        url,
        method,
        withCredentials: true,
        headers: h,
    }

    // get 将data放到params中
    if (method === 'get') {
        query.params = data
    } else {
        query.data = bodyData
    }


    return service(query) as Promise<any>
}

const post = (url: string, data: any, options: RequestOptions = {}): Promise<any> => {
    return request({
        ...options,
        url, data, method: 'post'
    })
}

const put = (url: string, data: any, options: RequestOptions = {}): Promise<any> => {
    return request({
        ...options,
        url, data, method: 'put'
    })
}

const del = (url: string, data?: any, options: RequestOptions = {}): Promise<any> => {
    Object.assign(options, {url, data, method: 'delete'})
    return request({
        ...options,
        url, data, method: 'delete'
    })
}

const get = (url: string, data?: any, options: RequestOptions = {}): Promise<any> => {
    Object.assign(options, {url, data, method: 'get'})
    return request({
        ...options,
        url, data, method: 'get'
    })
}

const form = (url: string, data: any, options: RequestOptions = {}): Promise<any> => {

    return request({
        ...options,
        url,
        method: 'post',
        data,
        headers: {'Content-Type': 'multipart/form-data'}
    })
}

export {
    post,
    put,
    get,
    del,
    form
}

export default service
