const CryptoJS = require('crypto-js')
//
// /**
//  * MD5加密
//  * @param text 明文
//  * @param secret 密钥
//  * @return
//  */
// function md5Encode(value, salt) {
//     return CryptoJS.MD5(value + salt).toString()
// }

export function encryptByAESPKCS7(content: string, secretKey: string, iv: string) {
    let ivHex = CryptoJS.enc.Utf8.parse(iv);
    let messageHex = CryptoJS.enc.Utf8.parse(content);
    let secretKeyHex = CryptoJS.enc.Utf8.parse(secretKey);

    const ciphertext = CryptoJS.AES.encrypt(messageHex, secretKeyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return ciphertext.toString();
}