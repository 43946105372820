import {isBlank} from "@/utils/validate";


export function distinct<T extends Record<any, any>>(arr: T[], key: string):T[] {
    let set: any = {}
    let narr:T[] = []
    for (let e of arr) {
        if (set[e[key]]) {
            continue
        }
        set[e[key]] = true
        narr.push(e)
    }
    return narr
}

export function toDate(timestamp: any): Date | null {
    if (timestamp instanceof Date) {
        return timestamp
    }

    if (typeof timestamp === "number") {
        return new Date(timestamp)
    }

    return null
}

export function toInt(v: any): Number | null {

    if (typeof v === "number") {
        return v
    }

    if (typeof v === "string") {
        try {
            return parseInt(v)
        } catch (e) {
            return null
        }

    }

    return null
}


export function split(ids: string | null): string[] {
    if (!(typeof ids == "string") || isBlank(ids)) {
        return []
    }
    ids = ids.replace(/ /g,"")
    ids = ids.replace(/，/g,"")
    let arr = ids.split(",")
    return arr
}