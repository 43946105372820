import {get, post, put} from './request'

const proxyUrl = process.env.VUE_APP_BASE_URL + '/sms'

export enum SMSVerificationCodeType {
    /**
     * 注册
     */
    REGISTER = 1,
    /**
     * 找回密码
     */
    GET_BACK_PASSWORD = 2
}
export default {

    // 发送验证码
    sendVerificationCode: (data: { tel: string, type: SMSVerificationCodeType }): Promise<{ accessToken: string, id: string }> => post(`${proxyUrl}/send_code`, data),
}
