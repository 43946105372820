import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Layout from "@/views/layout/index.vue";
import {toInt} from "@/utils/utils";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
    component: Layout,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/HomeView.vue'),
        meta: {title: '首页', keepAlive: true},
      },
      {
        path: 'practice',
        component: () => import('@/views/homepractice/PracticeHomeView.vue'),
        meta: {title: '练习刷题', keepAlive: true}
        // props: route => ({id: route.params.id})
      },
      {
        name: "teacherDisplayListView",
        path: 'teacher_displays',
        component: () => import('@/views/teacher/TeacherDisplayListView.vue'),
        meta: {title: '名师介绍', keepAlive: true}
        // props: route => ({id: route.params.id})
      },
      {
        path: 'datasources/:id',
        component: () => import('@/views/appconfig/AppConfigDatasourceView.vue'),
        meta: {title: '更多'},
        props: route => ({id: route.params.id, listStyle: toInt(route.query.listStyle)})
      },
      {
        path: 'resources',
        name: 'resourceListView',
        component: () => import('@/views/appconfig/AppConfigResourceListView.vue'),
        meta: {title: '资源'},
        props: route => ({menuId1: route.query.menuId1, menuId2: route.query.menuId2})
      },

      {
        path: 'news/:id',
        name: 'NewsDetail',
        component: () => import('@/views/news/NewsDetail.vue'),
        meta: {title: '资讯详情'},
        props: route => ({id: route.params.id})
      }

    ]
  },
  {
    path: '/',
    name: 'resource',
    component: Layout,
    children: [
      {
        path: '/resources/search',
        component: () => import('@/views/resource/ResourceSearchView.vue'),
        meta: {title: '资源搜索', fullscreen: false}
        // props: route => ({id: route.params.id, resourceId: route.query.resourceId})
      },
      {
        path: '/resources/:id',
        component: () => import('@/views/resource/ResourceDetailView.vue'),
        meta: {title: '资源'},
        props: route => ({id: route.params.id})
      },
      {
        path: '/resources/items/:id',
        component: () => import('@/views/resource/item/ResourceItemDetailView.vue'),
        meta: {title: '资源', fullscreen: true},
        props: route => ({id: route.params.id, videoFullscreen:false, resourceId: route.query.resourceId})
      },
      {
        path: '/resources/items/:id/fullscreen',
        component: () => import('@/views/resource/item/ResourceItemDetailView.vue'),
        meta: {title: '资源', fullscreen: true},
        props: route => ({id: route.params.id, videoFullscreen: true, resourceId: route.query.resourceId})
      }
    ]
  },
  {
    path: '/papers',
    name: 'papers',
    component: Layout,
    children: [
      {
        path: ':paperId/questions/:questionId',
        component: () => import('@/views/paper/PaperForOneQuestionView.vue'),
        meta: {title: '题目预览'},
        props: route => ({paperId: route.params.paperId, questionId: route.params.questionId})
      },
      {
        path: 'records/:id/doing',
        component: () => import('@/views/paper/PaperView.vue'),
        meta: {title: '答题', fullscreen: true},
        props: route => ({recordId: route.params.id, paperId: route.query.paperId})
      },
      {
        path: 'records/:id/report',
        component: () => import('@/views/paper/PaperReportView.vue'),
        meta: {title: '答题报告', fullscreen: true},
        props: route => ({id: route.params.id, paperId: route.query.paperId})
      },
      {
        path: ':id/preview',
        component: () => import('@/views/paper/PaperPreviewView.vue'),
        meta: {title: '试题预览'},
        props: route => ({id: route.params.id})
      }
    ]
  },
  {
    path: '/wrongbooks',
    name: 'wrongBook',
    component: Layout,
    children: [
      {
        path: ':id',
        component: () => import('@/views/wrongbook/WrongBookInfoView.vue'),
        meta: {title: '错题本预览'},
        props: route => ({id: route.params.id})
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/accounts/get_back_password',
        name: "GetBackPassword",
        component: () => import('@/views/user/GetBackPassword.vue'),
        meta: {title: '找回密码'}
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: () => import('@/views/layout/MineLayout.vue'),
        children: [
          {
            path: '/my_courses',
            component: () => import('@/views/mine/MyResourceView.vue'),
            props: route => ({type: 1})
          },
          {
            path: '/my_practice_books',
            component: () => import('@/views/mine/MyResourceView.vue'),
            props: route => ({type: 2})
          },
          {
            path: '/profile',
            component: () => import('@/views/mine/Profile.vue'),
            props: route => ({type: 2})
          },
          {
            path: '/practice/history_records',
            component: () => import('@/views/mine/PracticeHistoryRecordView.vue'),
            meta: {title: '做题记录'}
          },
          {
            path: '/practice/wrong_books',
            component: () => import('@/views/wrongbook/WrongBookListView.vue'),
            meta: {title: '错题本'}
          },
          {
            path: '/practice/question_marks',
            component: () => import('@/views/question/QuestionMarkListView.vue'),
            meta: {title: '错题本'}
          }
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    redirect: "/home"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
